import { Space, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Logo from './../../Images/logo.png';
import LoadingPage from '../../Components/Loading';
import Funcoes from './funcoes';
import './login.css';

function Login() {

    const { Load, Logar, Usuario, setUsuario, Senha, setSenha, Security, setSecurity } = Funcoes();

    return (
        <div className='Container'>
            <div className='From'>

                <div className='ContainerLogo'>
                    <img className='Logo' src={Logo} />
                </div>

                <Space style={{ flexDirection: 'column', marginBottom: 25 }}>

                    <div style={{ alignItems: 'end', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <input value={Usuario} onChange={(e) => setUsuario(e.target.value)} className={'Input'} placeholder="Email ou Usuário" />
                    </div>

                    <div style={{ alignItems: 'end', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <input value={Senha} onChange={(e) => setSenha(e.target.value)} type={!Security ? "password":'text'} className={'Input'} placeholder="Senha" />
                        <Button onClick={() => setSecurity(!Security)} style={{ position: 'absolute', marginRight: 10, backgroundColor: '#2d800c' }} type="primary" shape="circle" icon={Security ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                    </div>

                </Space>
                 
                <Button onClick={Logar} type="primary" className='BtnLogar'>Entrar</Button>

                <Button
                    onClick={() => window.open('https://wa.me/3535311074?text=Oi!%20Preciso%20de%20ajuda%20com%20o%20meu%20acesso%20ao%20Painel%20AcupunturaPro.', '_blank')}
                    type="primary"
                    shape="circle"
                    icon={<WhatsAppOutlined style={{ fontSize: '24px' }} />}
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        right: 20,
                        width: 50,
                        height: 50,
                        backgroundColor: '#2d800c'
                    }}
                />

            </div>

            {Load && <LoadingPage />}

        </div>
    );
}

export default Login;
